.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-removed)
  > td {
  background-color: #3aa9ae;
  color: #fff;
}
.tooltip {
  background-color: #fff !important;
  color: rgba(32, 31, 31, 0.9) !important;
  border-radius: 0 !important;
  border: solid 1px rgba(32, 31, 31, 0.9) !important;
  box-shadow: none;
}
.dx-datagrid-focus-overlay {
  border: none;
}
.dx-datagrid .dx-header-filter {
  color: #3aa9ae;
}
.dx-datagrid .dx-header-filter-empty {
  color: rgba(149, 149, 149, 0.5);
}
.dx-datagrid-header-panel {
  padding-right: 0;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  text-align: left !important;
}
